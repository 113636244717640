.NavigationItems { 
    min-height: 48px;
    width: auto;
    text-align: center;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    list-style: none outside;
}


.NavigationItem {
    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    text-decoration: none;
    text-align: left;
    color: #fff;
} 


.NavigationItem a {
    color: white;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
    text-decoration: none;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    background-color: #4c443b;
    border-bottom: 4px solid #33CCFF;
    color: white;
    text-decoration: none;
}

.SoundToggle {
    position: absolute;
    right: 20px; 
    top: 10px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SoundToggle img {
    width: 100%;
    height: auto;
}
