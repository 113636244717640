@keyframes rotating {
    100% {
        transform: rotate(360deg);
    }
}

.Loader {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top: 6px solid #555;
    animation: rotating 1.2s infinite linear;
}
