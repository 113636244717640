.ContentCloud {
    width: 60%;
    height: 60%;
    margin: auto;
    margin-top: 7%;
    animation: showContent 1s ease-out forwards;
    font-size: 0.9vw;
    text-align: center;
  
}


@keyframes showContent {
    0% {
        opacity : 0;
        transform: translateY(-40%);
    }

    100% {
        opacity : 1;
        transform: translateY(0);
    }
}

/* .fade {
    animation: fade-in-keyframes 1s;
  }
  @keyframes fade-in-keyframes {
    from {opacity: 0}
    to {opacity: 1}
  } */

/* 
.enter {
    opacity : 0;
}
.enterActive {
    opacity: 1;
    transform: opacity 1.3s ease-out;
}

.leave {
    opacity: 1;
}

.leaveActive {
    opacity: 0;
    transform: opacity 1.3s ease-out;
}

.appear {
    opacity: 1;
}

.appearActive {
    opacity: 0;
    transform: opacity 1.3s ease-out;
}


@keyframes closeModal {
    0% {
        opacity : 0;
        transform: translateY(-10%);
    }

    50% {
        opacity : 0.8;
        transform: translateY(-20%);
    }

    100% {
        opacity : 1;
        transform: translateY(-50%);
    }
}

 */

