
@keyframes clignote {
    0%{stroke : #33CCFF; stroke-width: 5px;}
    50%{stroke : transparent;}
    100%{stroke : #33CCFF; stroke-width: 5px;}
}

@keyframes clignote1 {
    0%{stroke : #33CCFF; stroke-width: 30px;}
    50%{stroke : transparent;}
    100%{stroke : white; }
}
.ActiveMarker {
    fill : rgb(223, 226, 51);
    /*stroke : #FFFFFF;*/
    animation : clignote1 0.8s ease-out forwards;

}
.Marker {
    fill: #33CCFF;
    animation : clignote 3s ease-out infinite;
    
}

.Marker:hover {
    stroke : rgb(223, 226, 51);
    cursor:pointer;
    
}