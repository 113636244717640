.PresentationMode {

    justify-content: center;
    text-align: center;
    height: 100vh;
    background-color: transparent;

}

.Legend {
    font-size: 16px !important;
}
