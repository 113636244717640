
.Resume {
    margin-top: 45px;
    
    height: calc(100vh - 45px);
/*     background-color: black; */
    background-size: cover !important;
    -webkit-background-size: cover !important;
  
   
}

.Logo {
    position:absolute;
    bottom: 0;
    left:5%;
    width: 25%; 
}
.Wrapper {
    display: flex;
    flex-flow: row; 
    justify-content: space-around;
    
}

.ThinkContainer {
    position: relative;
    width:50%;
    /* min-height: 700px; */
    
     
   
}
@keyframes clignote {
    0%{box-shadow: 0px 0px 50px white;}
    50%{box-shadow: 0px 0px 0px white;}
    100%{box-shadow: 0px 0px 50px white;}
}

.Cloud1 {
 /*    width: 35px;
    height:30px;
    border-radius: 30px;
    background: #f8f9f8;
    position: absolute;
    left:250px;
    bottom: 250px; */
    width: 4%;
    height: 4%;
    border-radius: 55%;
    background: #f8f9f8;
    position: absolute;
    left: 25%;
    bottom: 30%;
    animation : clignote 3s ease-out infinite;
}
.Cloud2 {
/*      width: 70px;
    height: 55px;
    border-radius: 100px;
    background: #f8f9f8;
    position: absolute;
    left: 320px;
    bottom: 300px;
     */
    width: 8%;
    height: 8%;
    border-radius: 100%;
    background: #f8f9f8;
    position: absolute;
    left: 30%;
    bottom: 37%;

    animation : clignote 3s ease-out infinite;

}
.Cloud3 {
/*     width: 500px;
    height: 400px;
    border-radius: 700px;
    background: #f8f9f8;
    position: absolute;
    left: 401px;
    bottom: 300px; */
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background: #f8f9f8;
    position: absolute;
    left: 40%;
    top: 10%;
    animation : clignote 3s ease-out infinite;
    
}
.Cloud3 img {
    width:70%;
}

@media (max-width: 600px) {
    .Cloud3 img {
      display: none;
    }
  }


.MapContainer {
    width: 40%;
   /*  height: 800px; */
    height: calc(100vh - 150px);
    background-color: rgba(245,245,245, 0);
    position: relative;
    text-align: center;
    

}

