.Contacts {
    background-color:gray;
    width:100%;
    height: calc(100vh - 56px);
    position:relative;
    padding-top : 56px;
/*     display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; */
}

