
.Nutshell{
    color: white;
    position: absolute;
    top: 15%;
    left: 15%;
    /* font-size: 35px; */
    font-size: 1.8vw;
    font-weight: bold;
    text-align: left;
    z-index :10;
} 

.Iam{
    color: white;
    position: absolute;
    z-index:200;
    bottom: 10%;
    left: 30%;
/*     font-size: 30px;*/
    font-weight: bold; 
    text-align: left;
} 

.Value {
    /* color:#ce8282; */
    /* color:#ce9178; */
    /* color : rgb(206,145,91); */
    color : rgb(236, 160, 93)
    
    
}
.Param {
    /* color: #68abd7; */
    color : rgb(156,220,254);
    margin-left: 50px;
    /* font-size: 30px; */
    font-size: 1.5vw;
}
.Return {
    /* color : rgb(187,134,161); */
    color : rgb(212, 148, 180);
    font-size: 1.5vw;
}