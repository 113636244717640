
body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heading {
  font-weight: 700;
}
.subheading {
  font-weight: 300;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

