.Slider {
    /* list-style: none;
    border-left: 2px solid  #33CCFF9e;
    width: 2px;
    max-width: 50px;
    margin: 2%;
    position: relative;
    color: white;
    max-height: 500px; */
    list-style: none;
    border-left: 1px solid rgba(223, 226, 51, 0.75);
    width: 1px;
    max-width: 50px;
    margin: 2%;
    position: relative;
    color: white;
    max-height: 500px;

}

.Slider li {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.Slider li:hover {
    font-weight: bold;
    color:  rgb(223, 226, 51);
    cursor: pointer;
}

.Cercle {

    /* width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #33CCFF;
    position: absolute;
    left: 10px;
    margin-left: -19px; */
    border-radius: 20px;
    background-color: rgb(223, 226, 51);
    position: absolute;
    left: 10px;
    margin-left: -13px;
}

.Cercle:hover {
    box-shadow: 3px 3px 3px 3px  rgb(223, 226, 51);
}

.Cercle.active {
    /* box-shadow: 3px 3px 3px 3px  rgb(223, 226, 51);
    background-color: transparent; */

    width: 5px;
    height: 5px;
}