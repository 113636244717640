.Portfolio {
    width : 100%;
/*     background-color: rgba(128, 128, 128, 0.19); */
}

.Container {
    width: 80%;
    margin: 0 auto;
    background-color:transparent;
    padding-top:50px
}