.FormContact {
    width: 100px;
    height: 100px;
    color: green;
}

.Btn {
    /* display: inline-block;
    padding: 0.25rem 1rem;
    text-decoration: none;
    font: inherit;
    border: 1px solid #33CCFF;
    color: #33CCFF;
    background: white;
    border-radius: 3px; */
 
    cursor: pointer;
    width: 100%;
    border: 1px solid #33CCFF;
    padding: 0.5rem;
    background: #33CCFF;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-top: 2%;
  }
  
.Btn:hover,
.Btn:active {
    border: 1px solid #4395b1;
}

.Btn.danger {
    color: red;
    border-color: red;
  }
  
.Btn.danger:hover,
.Btn.danger:active {
background: red;
color: white;
}


.FormControl {
    margin: 1rem 5%;
    width: 20%;
}

.FormControl label,
.FormControl input,
.FormControl textarea {
    display: block;
    width: 100%;
    margin-bottom: 0.6rem;
    box-sizing: border-box;
}

.FormControl input::placeholder,
.FormControl textarea::placeholder {
   font-style: italic;
   font-size:  1em;
    padding: 3px;
    opacity: 0.5;
}


.FormControl textarea {
    max-height: 10vw;
    min-height: 8vw;
}


.FormControl input,
.FormControl textarea {
    border: 1px solid #a1a1a17a;
    font: inherit;
    border-radius: 4px;
    padding: 3px;
    
}

.FormControl input:focus,
.FormControl textarea:focus {
    outline-color: #33CCFF;
}

.FormControl .Invalid {
     border-color: red;
    background: rgb(251, 236, 242);
}
.UserMsg {
    /* width: 97%;
    border: 1px solid #4771fa;
    padding: 0.5rem;
    border-radius: 3px;
    background: #b9c9ff;
    text-align: center; */

    width: 100%;
    border: 1px solid #1d9dc7;
    padding: 0.5rem;
    border-radius: 3px;
    background: #33CCFF;
    text-align: center;
    color: white;
    box-sizing: border-box;
}

.UserMsgError {
    border-color: red;
    background: rgb(255, 176, 176);
    color: red;
}

.Hidden {
    visibility: hidden;
}