
.SliderItem{
    /* font-size: 1vw;
    text-align: center; */
    
    font-size: 0.9vw;
    text-align: center;
    font-style: italic;
    color: #33CCFF;
}
.ActiveSliderItem{
    /* font-weight: bold;
    color:  rgb(223, 226, 51);
    font-size: 1vw;
    text-align: center; */

    font-weight: bold;
    color: rgb(223, 226, 51);
    font-size: 1vw;
    text-align: center;
    font-style: italic;
}