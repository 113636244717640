.Contact {
    height: 8%;
    width: 4%;
    border-radius: 50%;
    margin: 0 auto;
    z-index: 100;
    position: absolute;
    border: 1px solid #33CCFF;
    cursor: pointer;
}
.Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    margin-top: 1%;
}

.Iam {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}


.Head1 {
    display: block;
    font-size: 2.5em;
    margin :4%;
    font-weight: bold;
}
.Head3 {
    display: block;
    font-size: 1.5em;
    margin :4%;
    font-weight: bold;
}

.Head3 a {
    color : #33CCFF;
}
.Head4 {
    display: block;
    font-size: 1.2em;
    margin :3%;
    font-weight: bold;
    white-space: nowrap;
}
.Oim {
    width: 10%;
    border-radius: 50%;
}

.Arrow {
    width: 5%;
    position: absolute;
    bottom: 10%;
    left: 52%;
    /* width: 15%;
    position: absolute;
    top: 55%;
    left: 20%; */
}

