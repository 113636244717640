.TitleCity {
    width: 150px;
    height: 50px;
    background-color: white;
    animation: slideLeft 0.8s ease-out forwards;
    position: absolute;
    top: 5%;
    left: 2%;
    font-size: 20px;
    line-height: 50px;

}

.Border {
    border-radius: 5px;
   /*  border: 3px solid #77bb79bf; */
     
}

.ImageCity {   
    animation : slideRight 0.8s ease-out forwards;
    position: absolute;
    bottom : 5%;
    right : 2%;
    width:35%;
}

@keyframes slideRight {
    0% {
        opacity : 0;
        transform: translateX(-200%);
       
    }
    50% {
        opacity : 0.8;
        transform: translateX(1%);
        border: 3px solid #33CCFF;
    }
    100% {
        opacity : 1;
        transform: translateX(0);
        border: 3px solid rgba(223, 226, 51, 0.77);
    }
}
@keyframes slideLeft {
    0% {
        opacity : 0;
        transform: translateX(200%);
        
    }
    50% {
        opacity : 0.8;
        transform: translateX(-20%);
        border: 3px solid #33CCFF;
    }
    100% {
        opacity : 1;
        transform: translateX(0);
        border: 3px solid rgba(223, 226, 51, 0.77);
    }
}