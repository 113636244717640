.Toolbar {
/*     height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color:black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90; */


    font: 0.7vw 'opensans-bold', sans-serif;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin: 0 auto;
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;

}


